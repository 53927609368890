<template>
  <input
    :id="elementId"
    :class="{
      disabled: $attrs.readonly,
      'border-red-500': hasError
    }"
    :name="elementId"
    :tabindex="tabindex"
    :value="modelValue"
    class="placeholder:text-slate-400 placeholder:text-sm focus:border-sky-500 focus:ring-2 placeholder:2xl:text-lg"
    @input="updater"
  />
</template>
<script setup>
const emitEvent = defineEmits(['update:modelValue', 'change']);
defineProps({
  modelValue: {
    type: [String, Number],
    default: ''
  },
  tabindex: {
    type: Number,
    default: 0
  },
  elementId: {
    type: String,
    required: true
  },
  hasError: {
    type: Boolean,
    default: false
  }
});
const updater = (evt) => {
  emitEvent('update:modelValue', evt.target.value);
  emitEvent('change', evt);
};
</script>
